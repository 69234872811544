<template>
  <div id="statusTable">
    <!--
        <v-card :class="this.$bgColor" class="outer-window" height="75vh">
          <section class="inner-window">
            <v-card class="table-container">
              <v-data-table
                disable-pagination
                class="flex-table"
       -->
    <v-card :class="this.$bgColor" :height="height" class="view">
      <v-card :color="this.$fgColor" height="60" flat class="top">
        <v-row justify="center">
          <h3>
            <Tip
              text=" Click on any column to sort. <br /><br/>Click on a row to see a list of  rides for the Ride Leader."
              :icon-bg="this.$bgColor">
              Ride Status {{ year }}
            </Tip>
          </h3>
        </v-row>
        <v-row justify="center">
          <p class="sub-heading"><i>click on a Ride Leader to see their rides</i></p>
        </v-row>
      </v-card>
      <v-card class="table-container">
        <v-data-table hide-default-footer single-expand disable-pagination class="flex-table row-height" multi-sort
          fixed-header :headers="headers" item-key="rideLeader" :items="summaryData" :class="this.$bgColor"
          @click:row="(item, slot) => newRL(item, slot)">
          <template v-slot:header.rideLeader="{}"> Ride Leader </template>
          <template v-slot:body.prepend>
            <tr class="total-row">
              <td></td>
              <td v-for="(k, v) in total" :key="v" class="my text-wrap">
                {{ k }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ item }">
            <tr>
            </tr>

            <td colspan="6">
              <RidesTable :key="myKey" :ride-leader="item.rideLeader" ride-status="all" />
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script>
// import axios from "axios";
import RidesTable from "../components/RidesTable.vue";
import EventBus from "../event-bus.js";
import mixins from "../components/mixins";
import Tip from "../components/Tip";

export default {
  name: "StatusTable",
  components: {
    RidesTable,
    Tip,
  },
  mixins: [mixins],
  props: {
    rideLeader: {
      type: String,
      required: false,
      default: "",
    },
    rideStatus: {
      type: String,
      required: false,
      default: "",
    },
    /*     rideList: {
      Type: Array,
    },

 */ height: {
      type: String,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      myKey: 0,
      total: {},
      render: false,
      year: "loading...",
      changeID: "",
      showRefresh: false,
      showNewYear: true,
      summaryData: [],
      //      expanded: [],
      calendar: this.$calendar,
      rl: this.rideLeader,
      rideLeaderList: [],
      newRideLeader: "",

      windowSize: {
        x: 0,
        y: 0,
      },

      headers: [],
      pending: true,
    };
  },

  async mounted() {
    EventBus.$on("resetStatusTable", (value) => {
      //this.newRL(value);
      this.summaryData = [];
      this.headers = [];
      value;

      if (value === true) {
        this.getRides("all", "all").then((resp) => {
          if (resp === "error") return "error";
          this.createSummary(resp);
          value;
        });
      }
    });


    if (this.rideList === undefined || this.rideList.length === 0) {
      var results = await this.getCalData();
      //.then((results) => {
      //     console.log(this.pp(results));
      if (results === undefined || results === "error") {
        this.handleError("getCalData return " + results);
        return "error";
      }
      //      });
      await this.getRides("all", "all").then((response) => {
        //          this.rideList = response;
        this.pendID = this.getStatusID("pend");
        this.changeID = this.getStatusID("change");
        /*
                  this.changeName = this.getStatusName("change");
                  this.pendName = this.getStatusName("pend");
         */
        response = response.sort((a, b) => a.who > b.who ? 1 : -1); // b - a for reverse sort

        this.createSummary(response);
      });

    } else {
      this.createSummary(this.rideList);
      //      this.rideList = this.rideList2;
      //    this.createSummary(this.rideList2);
    }
  },

  methods: {
    /*
    onResize(pixels = 0) {
      this.windowSize = {
        x: window.innerWidth,
        y: window.outerHeight + pixels,
      };
    },
*/
    async newRL(it, slot = null) {
      if (slot != null) slot.expand(!slot.isExpanded);
      //this forces the table refresh
      //   this.summaryData = [];
      //this.headers = [];
      var rd = it.rideLeader;
      await this.getRides(rd, "all").then(() => {
        this.myKey += 1;
        //  this.createSummary(resp);
      });
      await this.getRides("all", "all", "force").then((response) => {
        this.rideList = response;
      });
    },
    /*     async newRL(it, slot) {
      this.newRideLeader = it.rideLeader;
      slot.expand(!slot.isexpanded);
      //  slot.height = 12;
      //      this.getRides(this.newRideLeader, "all");
      this.headers = [];
      this.getRides("all", "all").then((resp) => {
        this.rideList = resp;
        this.createSummary(resp);
      });
    },
  */
    createSummary(response) {
      EventBus.$emit("wait", true);

      // create table headings
      this.headers.push({
        value: "spacer",
      });
      this.headers.push({
        text: "RIDE LEADER",
        value: "rideLeader",
        width: "135",
        align: "start",
      });
      // get all the ride status field names
      // and use them for colmn headings
      var keys = Object.values(this.status.choices);

      for (var i = 0; i < keys.length; i++) {
        this.headers.push({
          text: keys[i].name,
          value: keys[i].name,
          width: "105",
        });
        //       console.log("heading id " + keys[i].id);
      }

      this.headers.push({
        value: "spacer-end",
      });
      // create a row for totals
      this.total[" rideLeader"] = 0;
      for (i = 0; i < keys.length; i++) {
        this.total[keys[i].name] = 0;
      }

      // create the results table
      //    var summaryData = [];
      this.render = false;
      //      this.summaryData = [];
      for (var cnt = 0; cnt < response.length; cnt++) {
        var ride = response[cnt];
        if (ride.who === "") {
          continue;
        }
        var rideLeaderIndex = this.summaryData.findIndex(
          (obj) => obj.rideLeader == ride.who
        );

        // ride leader not found in summary data
        // so add this name to the list
        if (rideLeaderIndex === -1) {
          this.summaryData.push({
            rideLeader: ride.who,
          });

          rideLeaderIndex = this.summaryData.findIndex(
            (obj) => obj.rideLeader == ride.who
          );
          if (rideLeaderIndex === -1) {
            this.handleError(
              "no ride leader " + ride.who + " not found in summary data"
            );
            continue;
          }
          // setting the status fields to zereo
          this.summaryData[rideLeaderIndex]["spacer"] = "";
          for (i = 0; i < keys.length; i++) {
            this.summaryData[rideLeaderIndex][keys[i].name] = 0;
          }
          this.summaryData[rideLeaderIndex]["spacer-end"] = "";
        }

        var id = this.$store.state.status.id;
        if (ride.custom !== undefined) {
          var rideStatus = this.displayCustom(id, ride.custom);

          this.summaryData[rideLeaderIndex][rideStatus.value] += 1;
          this.total[rideStatus.value] += 1;
        }
      }

      //      this.summaryData = summaryData;
      this.total[" rideLeader"] = "Totals: " + this.summaryData.length;
      EventBus.$emit("summauryData", this.summaryData);
      this.year = this.getYear("current");
      EventBus.$emit("wait", false);
      this.render = true;

    },
  },
  //mounted() {
  //  this.onResize();
  //    },
};
</script>

<style>
.view {
  display: flex;
  flex-direction: column;
}

.table-container {
  display: flex;
  overflow: hidden;
}

.flex-table>div {
  width: 100%;
  height: 300;
}

.flex-table {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

/*
  background-color: skyblue;
  */
.total-row td {
  font-weight: bold;

  border-top: thin solid rgba(0, 0, 0, 0.12);
}

/*
.my {
  width: 162px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 12px;
}
*/

.top {
  margin-top: 0px;
  padding-top: 20px;
}
</style>
